import React, { useState } from "react";

const CreateEditObjectives = ({ courseData, setCourseData }) => {
  // Character limits
  const OBJECTIVE_LIMIT = 150; // Maximum characters for objectives
  const REQUIREMENTS_LIMIT = 500; // Maximum characters for requirements

  // State for requirements
  const [requirements, setRequirements] = useState("");

  return (
    <div className="p-4 bg-white rounded-md shadow-md">
      {/* Course Objectives Section */}
      <div className="mb-4">
        <p className="font-semibold text-lg text-stone-800">Course Objectives</p>
      </div>
      <p className="font-medium text-stone-700">Main Objectives</p>
      <p className="text-sm text-stone-500 mt-1 mb-3">
        Direction is key: define where you will take your learners with this course.
      </p>
      <div className="space-y-3">
        {/* Objective 1 */}
        <div>
          <input
            placeholder="Objective 1"
            className="focus:outline-none p-2 border rounded-md w-full text-sm"
            value={courseData.objectives.one}
            onChange={(e) => {
              if (e.target.value.length <= OBJECTIVE_LIMIT) {
                setCourseData((prev) => ({
                  ...prev,
                  objectives: {
                    ...prev.objectives,
                    one: e.target.value,
                  },
                }));
              }
            }}
          />
          <div className="flex justify-between items-center mt-1 text-xs text-stone-500">
            <p>Maximum {OBJECTIVE_LIMIT} characters.</p>
            <p>{courseData.objectives.one.length} / {OBJECTIVE_LIMIT}</p>
          </div>
        </div>

        {/* Objective 2 */}
        <div>
          <input
            placeholder="Objective 2"
            className="focus:outline-none p-2 border rounded-md w-full text-sm"
            value={courseData.objectives.two}
            onChange={(e) => {
              if (e.target.value.length <= OBJECTIVE_LIMIT) {
                setCourseData((prev) => ({
                  ...prev,
                  objectives: {
                    ...prev.objectives,
                    two: e.target.value,
                  },
                }));
              }
            }}
          />
          <div className="flex justify-between items-center mt-1 text-xs text-stone-500">
            <p>Maximum {OBJECTIVE_LIMIT} characters.</p>
            <p>{courseData.objectives.two.length} / {OBJECTIVE_LIMIT}</p>
          </div>
        </div>

        {/* Objective 3 */}
        <div>
          <input
            placeholder="Objective 3"
            className="focus:outline-none p-2 border rounded-md w-full text-sm"
            value={courseData.objectives.three}
            onChange={(e) => {
              if (e.target.value.length <= OBJECTIVE_LIMIT) {
                setCourseData((prev) => ({
                  ...prev,
                  objectives: {
                    ...prev.objectives,
                    three: e.target.value,
                  },
                }));
              }
            }}
          />
          <div className="flex justify-between items-center mt-1 text-xs text-stone-500">
            <p>Maximum {OBJECTIVE_LIMIT} characters.</p>
            <p>{courseData.objectives.three.length} / {OBJECTIVE_LIMIT}</p>
          </div>
        </div>
      </div>
      <p className="text-xs text-stone-400 mt-3">
        Provide your learners with 3 clear objectives.
      </p>

      {/* Requirements Section */}
      <div className="mt-5">
        <p className="font-medium text-stone-700">Any Requirements?</p>
        <p className="text-sm text-stone-500 mt-1 mb-3">
          Tell your students if there are any prerequisites or how easy this course is for beginners.
        </p>
        <textarea
          rows="8"
          placeholder="Enter your course description..."
          className="focus:outline-none p-3 border rounded-md w-full text-sm"
          value={requirements}
          onChange={(e) => {
            if (e.target.value.length <= REQUIREMENTS_LIMIT) {
              setRequirements(e.target.value);
            }
          }}
        />
        <div className="flex justify-between items-center mt-1 text-xs text-stone-500">
          <p>Maximum {REQUIREMENTS_LIMIT} characters.</p>
          <p>{requirements.length} / {REQUIREMENTS_LIMIT}</p>
        </div>
      </div>
    </div>
  );
};

export default CreateEditObjectives;
