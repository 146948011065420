import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import GeneralNavbar from "../../../../components/navbar/GeneralNavbar";
import BlobComposition from "../../../../components/backgrounds/BlobComposition/BlobComposition";
import { IoMdSearch } from "react-icons/io";
import { RiDeleteBin7Fill } from "react-icons/ri";
import { FiBell } from "react-icons/fi";
import { useSelector } from "react-redux";
import OnHoverExtraHud from "../../../../components/OnHoverExtraHud";
import Pagination from "../../Profile/Pagination";
import getTeachers from "../../../../BackendProxy/adminProxy/getTeachers";

const AdminManageEducators = () => {
  const authUser = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [teachers, setTeachers] = useState([]);
  const [filteredTeachers, setFilteredTeachers] = useState([]); // For filtering
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedTeachers, setSelectedTeachers] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const totalPages = Math.ceil(filteredTeachers.length / itemsPerPage);

  const currentTeachers = filteredTeachers.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  useEffect(() => {
    const fetchTeachers = async () => {
      try {
        const res = await getTeachers(authUser.institution.code);
        setTeachers(res);
        setFilteredTeachers(res); // Initialize with full list
      } catch (error) {
        console.error("Error fetching teachers:", error);
      }
    };
    fetchTeachers();
  }, [authUser]);

  // Filter teachers based on search term
  useEffect(() => {
    const filtered = teachers.filter((teacher) =>
      teacher.username.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredTeachers(filtered);
    setCurrentPage(1); // Reset to first page when filter changes
  }, [searchTerm, teachers]);

  const handleCheckboxChange = (teacherId) => {
    setSelectedTeachers((prev) =>
      prev.includes(teacherId)
        ? prev.filter((id) => id !== teacherId) // Remove from selection
        : [...prev, teacherId] // Add to selection
    );
  };

  const toggleSelectAll = () => {
    const currentPageTeacherIds = currentTeachers.map((teacher) => teacher._id);
    const allSelected = currentPageTeacherIds.every((id) =>
      selectedTeachers.includes(id)
    );

    if (allSelected) {
      setSelectedTeachers((prev) =>
        prev.filter((id) => !currentPageTeacherIds.includes(id))
      );
    } else {
      setSelectedTeachers((prev) => [...new Set([...prev, ...currentPageTeacherIds])]);
    }
    setSelectAll(!allSelected);
  };

  useEffect(() => {
    const currentPageTeacherIds = currentTeachers.map((teacher) => teacher._id);
    const allSelected = currentPageTeacherIds.every((id) =>
      selectedTeachers.includes(id)
    );
    setSelectAll(allSelected);
  }, [selectedTeachers, currentTeachers]);

  const handleSendBulkNotification = () => {
    if (selectedTeachers.length === 0) {
      alert("No teachers selected for bulk notification.");
      return;
    }
    navigate("/admin/send-notification-teachers", {
      state: { teacherIds: selectedTeachers, sender: authUser.username },
    });
  };

  return (
    <div>
      <GeneralNavbar />
      <BlobComposition
        blobsData={[
          { top: "10%", left: "-20%", size: "700px" },
          { top: "-30%", left: "70%", size: "400px" },
          { top: "40%", left: "50%", size: "300px" },
        ]}
      />
      <div className="m-auto max-w-[1200px] mt-3 min-h-[100vh]">
        <div className="bg-white rounded-full flex justify-between items-center py-2 px-4">
          <p className="font-semibold text-lg">Manage Educators</p>
          <div className="flex items-center space-x-3">
            <div className="flex items-center">
              <input
                placeholder="Search by name"
                className="text-sm focus:outline-none focus:border-b-stone-400 border-b-transparent border-b-[1.5px] pr-2 py-1 font-medium text-stone-600"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <IoMdSearch />
            </div>
          </div>
        </div>

        <div className="my-2 flex items-center justify-between">
          {/* Invite Educators button aligned to the left */}
          <button
            onClick={() => navigate("/admin/invite/educators")}
            className="text-white font-medium px-3 py-1 rounded-full linearGradient_ver1 text-sm hover:scale-[1.05] transition-all"
          >
            + Invite Educators
          </button>

          {/* Send Notification button aligned to the right */}
          <button
            onClick={handleSendBulkNotification}
            className="text-white font-medium px-3 py-1 ml-3 rounded-full linearGradient_ver1 text-sm hover:scale-[1.05] transition-all"
          >
            Send Notification
          </button>
        </div>

        <div className="bg-white py-2 px-4 mt-2 rounded-lg">
          <table className="table-auto w-full">
            <thead>
              <tr>
                <th>
                {filteredTeachers.length > 0 && (
        <input
          type="checkbox"
          checked={
            filteredTeachers.length > 0 &&
            currentTeachers.every((teacher) => selectedTeachers.includes(teacher._id))
          }
          onChange={toggleSelectAll}
          className="w-5 h-5"
        />
      )}
                </th>
                <th>Username</th>
                <th>Email</th>
                <th className="text-end">Options</th>
              </tr>
            </thead>
            <tbody>
              {currentTeachers.map((teacher) => (
                <TeacherCard
                  key={teacher._id}
                  teacher={teacher}
                  handleCheckboxChange={handleCheckboxChange}
                  isChecked={selectedTeachers.includes(teacher._id)}
                />
              ))}
            </tbody>
          </table>
          {filteredTeachers.length > 0 && (
            <Pagination
              totalPages={totalPages}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          )}
          {filteredTeachers.length === 0 && (
            <div className="text-center py-4 text-gray-500">
              No teachers available.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const TeacherCard = ({ teacher, handleCheckboxChange, isChecked }) => {
  const navigate = useNavigate();
  const rowStyle = isChecked ? { backgroundColor: "#b2d8d8" } : { backgroundColor: "white" };

  const handleSendNotification = () => {
    navigate("/admin/send-notification-teachers", {
      state: { teacherIds: [teacher._id] },
    });
  };

  return (
    <tr style={rowStyle}>
      <td>
        <input
          type="checkbox"
          checked={isChecked}
          onChange={() => handleCheckboxChange(teacher._id)}
          className="w-5 h-5"
        />
      </td>
      <td>{teacher.username}</td>
      <td>{teacher.email}</td>
      <td className="flex items-center justify-end space-x-4">
        <button
          onClick={handleSendNotification}
          className="p-2 rounded-full bg-yellow-100 hover:bg-yellow-200 transition-all cursor-pointer flex items-center justify-center relative focus:outline-none hover-parent"
          aria-label="Send Notification"
        >
          <FiBell className="text-yellow-600" />
          <OnHoverExtraHud name="Send Notification" />
        </button>
      </td>
    </tr>
  );
};

export default AdminManageEducators;
